import { ResourcesEnum } from '@/utils/enum'

export default [
  // PRICING
  {
    path: 'pricing',
    name: 'pricing',
    component: () => import('@/views/private/pricing/Main')
  },

  // PRICING - CORPORATE

  {
    path: 'pricing/corporate',
    name: 'pricing.corporate',
    component: () => import('@/views/private/pricing/proposals/Main'),
    meta: {
      resourceName: [ResourcesEnum.storeInsurance, ResourcesEnum.akadperson]
    }
  },
  {
    path: 'pricing/corporate/:id',
    name: 'pricing.corporate.edit',
    component: () => import('@/views/private/pricing/proposals/Main'),
    meta: {
      resourceName: [ResourcesEnum.storeInsurance, ResourcesEnum.akadperson]
    },
    props: true
  },

  // PRICING - INDIVIDUAL

  {
    path: 'pricing/individual',
    name: 'pricing.individual',
    component: () => import('@/views/private/pricing/proposals/Main'),
    meta: {
      resourceName: ResourcesEnum.lifeInsurance
    }
  },
  {
    path: 'pricing/individual/:id',
    name: 'pricing.individual.edit',
    component: () => import('@/views/private/pricing/proposals/Main'),
    meta: {
      resourceName: ResourcesEnum.lifeInsurance
    },
    props: true
  },

  // PRICING - INDIVIDUAL

  {
    path: 'pricing/personal-accidents',
    name: 'pricing.personal-accidents',
    component: () => import('@/views/private/pricing/proposals/Main'),
    meta: {
      resourceName: ResourcesEnum.lifeInsurance
    }
  },
  {
    path: 'pricing/personal-accidents/:id',
    name: 'pricing.personal-accidents.edit',
    component: () => import('@/views/private/pricing/proposals/Main'),
    meta: {
      resourceName: ResourcesEnum.lifeInsurance
    },
    props: true
  },

  // LEADS
  {
    path: 'leads',
    name: 'leads.list',
    component: () => import('@/views/private/leads/List')
  },

  {
    path: 'leads/:leadId/corporate',
    name: 'leads.show.corporate',
    component: () => import('@/views/private/leads/show/Corporate'),
    props: true
  },

  {
    path: 'leads/:leadId/individual',
    name: 'leads.show.individual',
    component: () => import('@/views/private/leads/show/Individual'),
    props: true
  },

  {
    path: 'leads/:leadId/personal-accidents',
    name: 'leads.show.personal-accidents',
    component: () => import('@/views/private/leads/show/PersonalAccidents'),
    props: true
  }
]
