export default [
  // SALESMANS

  {
    path: '/salesmans/',
    name: 'admin.salemans.all',
    component: () => import('@/views/admin/salemans/Salesman')
  },

  // BROKERS

  {
    path: '/brokers/',
    name: 'admin.brokers.all',
    component: () => import('@/views/admin/brokers/Brokers')
  },

  // STORE CONFIG

  {
    path: '/config/general',
    name: 'config.general',
    component: () => import('@/views/admin/config/General')
  },

  // CHANNELS

  {
    path: '/config/channel',
    name: 'config.channel',
    component: () => import('@/views/admin/config/channel/Channel')
  },

  // NOTIFICATIONS

  {
    path: '/notifications/',
    name: 'notifications',
    component: () => import('@/views/admin/notifications/Notifications')
  },

  // MESSAGES (i18n)

  {
    path: '/config/message',
    name: 'config.message',
    component: () => import('@/views/admin/config/message/Message')
  },

  // CONTENTS

  {
    path: '/config/content',
    name: 'config.content',
    component: () => import('@/views/admin/config/content/Content')
  }
]
