import Vue from 'vue'

Vue.component('box', () => import('@/components/layout/box'))

Vue.component('renderHTML', {
  props: ['element', 'content'],
  render: function (createElement) {
    return createElement(this.element || 'p', {
      domProps: {
        innerHTML: this.content
      }
    })
  }
})
