import { isArray } from 'lodash'

const validateDomain = (email) => {
  const [_, domain] = email.split('@')

  return domain.match(/stone|vitta/)
}

const validateGroups = (groups) => isArray(groups) && groups.length >= 1

export { validateDomain, validateGroups }
