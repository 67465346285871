import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import privateRoutes from './private'
import publicRoutes from './public'
import adminRoutes from './admin'
import { validateDomain, validateGroups } from '@/utils/validate-sso'

Vue.use(VueRouter)

const beforeEnter = (to, from, next) => {
  if (store?.getters?.getKeycloak?.tokenParsed) {
    const isValidDomain = validateDomain(store.getters.getKeycloak.tokenParsed.email)
    if (!isValidDomain) return next({ path: '/invalid-email' })

    const isValidGroup = validateGroups(store.getters.getKeycloak.tokenParsed.groups)
    if (!isValidGroup) return next({ path: '/invalid-group' })
  }

  if (to.name.match('invalid')) return next({ path: '/' })
  return next()
}

const routes = [
  {
    name: 'private',
    path: '/',
    component: () => import('@/views/private/Main'),
    beforeEnter,
    redirect: '/pricing',
    children: privateRoutes
  },

  {
    name: 'admin',
    path: '/',
    component: () => import('@/views/admin/Main'),
    beforeEnter(to, from, next) {
      const userRules = store.state.private.user.realm_access.roles
      if (userRules.find((rule) => rule === 'supervisor' || rule === 'leader')) {
        return next()
      } else {
        return next({ path: '/invalid-group' })
      }
    },
    redirect: '/admin',
    children: adminRoutes
  },

  {
    name: 'public',
    path: '/',
    component: () => import('@/views/public/Main'),
    children: publicRoutes
  },

  {
    name: 'logout',
    path: '/logout',
    beforeEnter(to, from, next) {
      if (store.getters.getUser.token) {
        store.commit('user', {})
      }

      if (store.getters.getKeycloak.logout) {
        store.getters.getKeycloak.logout()
      }

      return window.location.reload()
    }
  },

  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const record = to.matched.find((record) => record.meta.resourceName)
  if (record && Vue.$featureAvailable(record.meta.resourceName)) {
    next(from.path)
  }

  next()
})

export default router
