import Vue from 'vue'
import axios from 'axios'

import { baseURL } from '@/utils/endpointApi'
import store from '@/store'

const preload = {
  install() {
    const allowRetry = {
      occupations: true,
      businessType: true,
      insurer: true,
      config: true,
      contents: true
    }

    const retry = (func) =>
      setTimeout(() => {
        func()
      }, 10000)

    const save = (name, data) => store.commit('preload', { name, data })

    async function occupations() {
      const { token } = store.state.keycloak
      if (!token) return

      try {
        const res = await axios.get(`${baseURL}v1/occupations?search=&limit=300&offset=0`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })

        save('occupations', res.data.data)
        return res.data.data
      } catch (error) {
        console.error(error)
        if (allowRetry.occupations) {
          allowRetry.occupations = false
          retry(occupations)
        }
      }
    }

    async function businessType() {
      const { token } = store.state.keycloak
      if (!token) return

      try {
        const res = await axios.get(`${baseURL}v1/businessType`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })

        save('businessType', res.data.data)
        return res.data.data
      } catch (error) {
        console.error(error)
        if (allowRetry.businessType) {
          allowRetry.businessType = false
          retry(businessType)
        }
      }
    }

    async function insurer() {
      const { token } = store.state.keycloak
      if (!token) return

      try {
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }

        const { data: store } = await axios.get(`${baseURL}v1/property/insurers`, options)
        const { data: life } = await axios.get(`${baseURL}v1/personal/insurers`, options)

        save('insurer', [...store, ...life])
        return [...store, ...life]
      } catch (error) {
        console.error(error)
        if (allowRetry.insurer) {
          allowRetry.insurer = false
          retry(insurer)
        }
      }
    }

    async function config() {
      const { token } = store.state.keycloak
      if (!token) return

      try {
        const res = await axios.get(`${baseURL}v1/config`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })

        save('config', res.data[0])
        return res.data[0]
      } catch (error) {
        console.error(error)
        if (allowRetry.config) {
          allowRetry.config = false
          retry(config)
        }
      }
    }

    async function contents() {
      const { token } = store.state.keycloak
      if (!token) return

      try {
        const res = await axios.get(`${baseURL}v1/content`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            active: true
          }
        })

        save('contents', res.data.data)
        return res.data.data
      } catch (error) {
        console.error(error)
        if (allowRetry.contents) {
          allowRetry.contents = false
          retry(contents)
        }
      }
    }

    occupations()
    businessType()
    insurer()
    config()
    contents()

    const reload = async (path) => {
      switch (path) {
        case 'occupations':
          return occupations()
        case 'businessType':
          return businessType()
        case 'insurer':
          return insurer()
        case 'config':
          return config()
        case 'contents':
          return contents()
        default:
          console.error(`preload path '${path}' not found`)
      }
    }

    Vue.prototype.$preload = (path) => reload(path)
  }
}

Vue.use(preload)
