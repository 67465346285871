import Vue from 'vue'
import Notifications from 'vue-notification'
import VueCrontab from 'vue-crontab'
import axios from 'axios'
import { cloneDeep } from 'lodash'

import { baseURL } from '@/utils/endpointApi'
import store from '@/store'

Vue.use(Notifications)
Vue.use(VueCrontab)

const removeSuffixCodeError = (messages) => {
  const unique = {}
  Object.keys(messages)?.forEach(function(key, _) {
    const unsuffixKey = key.slice(key.indexOf(".") + 1, key.length)
    unique[unsuffixKey] = messages[key]
  });
  return unique;
}

const i18n = {
  install() {
    const updateCache = () => {
      const { token } = store.state.keycloak

      if (!token) return
      axios
        .get(`${baseURL}v1/i18n/messages/all`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((request) => store.commit('i18n', removeSuffixCodeError(request.data.messages)))
        .catch((error) => console.error(error))
    }

    updateCache()
    VueCrontab.addJob({
      name: 'i18n',
      interval: { seconds: '0', minutes: '/15' },
      job: updateCache
    })

    const notify = (options) =>
      Vue.notify({
        duration: options.text.length < 15 ? 7500 : options.text.length * 500,
        title: options.title ?? 'Erro',
        type: options.type ?? 'error',
        text: options.text
      })

    const formatMessage = (message, field) => {
      const formated = cloneDeep(message)
      formated.text = formated.text.replace('{field_name}', field)
      return formated
    }

    const getFields = (error) => {
      if (typeof error === 'object' && error.response) {
        if (error.response.status === 503) {
          return 'UNAVAILABLE_SERVICE'
        } else {
          try {
            const code = error.response.data.code
            if (code) return [{ code }]

            const obj = error.response.data.field || []
            return obj
          } catch {
            return error
          }
        }
      }
      return error
    }

    const getMessage = (fields, extraKey) => {
      let message
      if (fields && fields.length > 0) {
        fields.forEach((field) => {
          let key
          if (field.code.includes('OPEN_INSURANCE_ERROR') && store.state.i18n[extraKey]) {
            key = extraKey
          } else key = field.code.slice(field.code.indexOf(".") + 1, field.code.length)

          const currentMessage = store.state.i18n[key]
          if (message) {
            message.text += ` ${formatMessage(currentMessage, field.field).text}`
          } else {
            message = formatMessage(currentMessage, field.field)
          }
        })
      } else message = store.state.i18n[extraKey] ?? store.state.i18n['default']

      return message
    }

    Vue.prototype.$i18n = (error, extraKey) => {
      const fields = getFields(error)
      const message = getMessage(fields, extraKey)

      if (message)
        notify({
          text: message.text,
          type: String(message.type).toLowerCase(),
          title: message.notification?.title
        })
      else
        notify({
          text: 'Não foi possível executar a operação'
        })
    }
  }
}

Vue.use(i18n)
