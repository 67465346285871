import Vue from 'vue'
import store from '@/store'

const showNotifications = async () => {
  const atualDateTime = new Date().toISOString()
  const { data: allNotificationsValids } = await Vue.prototype.$api.get(
    `v1/notification?active=true&startsAt[$lte]=${atualDateTime}&expiresAt[$gte]=${atualDateTime}`
  )
  const hiddenNotifications = store.getters.getHiddenNotifications
  const notificationsToDisplay = allNotificationsValids.data.filter(
    (notification) =>
      !Object.keys(hiddenNotifications).find(
        (hiddenNotification) => hiddenNotification === notification._id
      )
  )
  if (notificationsToDisplay.length)
    return Vue.$modal('notifications', 450).open({
      modalName: 'notificationsModal',
    }, { notifications: notificationsToDisplay })
}

export { showNotifications }
