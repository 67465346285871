import store from '@/store'
import { validateDomain, validateGroups } from '@/utils/validate-sso'

export default [
  {
    path: '/invalid-email',
    name: 'invalidEmail',
    beforeEnter(to, from, next) {
      if (store?.getters?.getKeycloak?.tokenParsed) {
        const isValidDomain = validateDomain(store.getters.getKeycloak.tokenParsed.email)
        if (isValidDomain) return next({ path: '/' })
      }

      return next()
    },
    component: () => import('@/views/public/auth/Invalid')
  },
  {
    path: '/invalid-group',
    name: 'invalidGroup',
    beforeEnter(to, from, next) {
      if (store?.getters?.getKeycloak?.tokenParsed) {
        const isValidGroup = validateGroups(store.getters.getKeycloak.tokenParsed.groups)
        if (isValidGroup) return next({ path: '/' })
      }

      return next()
    },
    component: () => import('@/views/public/auth/Invalid')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/public/auth/Invalid'),
    props: true
  }
]
