<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <notifications width="450" position="top right" />

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <modal />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { validateGroups } from '@/utils/validate-sso'
import { showNotifications } from '@/utils/showNotifications'
import store from '@/store'
import '@/plugins/computed'

export default {
  name: 'App',

  components: {
    Modal: () => import('@/components/modal/Main')
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    }
  },
  methods: {
    ...mapActions(['setUser', 'setDowntime', 'setConfig', 'setFeatures'])
  },
  async mounted() {
    if (store?.getters?.getKeycloak?.tokenParsed) {
      const isValidGroup = validateGroups(store.getters.getKeycloak.tokenParsed.groups)
      if (isValidGroup) {
        try {
          const { data } = await this.$api.get('/v1/auth')
          const { data: config } = await this.$api.get('/v1/config')
          this.setFeatures(data.data.features)
          this.setUser({
            ...store.getters.getKeycloak.tokenParsed,
            brokers: data.data.brokers
          })
          this.setConfig(config[0])
          showNotifications()
        } catch (err) {
          if (err?.response?.status === 400) {
            this.$router.push({ name: 'error', params: { message: err.response.data.message } })
          } else {
            this.$router.push({
              name: 'error',
              params: { message: 'Error inesperado, por favor, contate o suporte' }
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.fade-enter-active, .fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
}
</style>
