import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/src/locale/pt.ts'

Vue.use(Vuetify)

export default new Vuetify({
  lang: { locales: { ptbr: pt }, current: 'ptbr' },
  theme: {
    dark: false,

    themes: {
      light: {
        primary: '#00a868',
        secondary: '#3fbd8d',
        background: '#f1f1f1',
        transparent: '#eafaf5'
      },

      dark: {
        primary: '#00a868'
      }
    },

    options: {
      customProperties: true
    }
  }
})
